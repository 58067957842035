<template>
      <v-snackbar
      v-model="showSnackbar"
      :timeout="timer"
      bottom 
      outlined
      center
      elevation="5"
      :color="color"
    >
      <v-icon class="mr-2" :color="color">{{icon}}</v-icon>
      <span :style="$vuetify.theme.isDark? 'color:#EBEAFA':'color:black'">{{ message }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn  icon v-bind="attrs" @click="showSnackbar = false"  >
          <v-icon class="mr-2">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
</template>
<script>
export default {
  name: "notify",
  data() {
    return{
      showSnackbar: false,
      message: '',
      color: 'success',
      icon: 'mdi-check',
      timer: 3000
    }
  },
  methods:{
    show(data) {
      this.message = data.message || 'missing "message".'
      this.color = (data.type === 'error' ? 'red' : 'success' ) || 'success'
      this.icon = (data.type === 'error' ? 'mdi-alert-outline' : 'mdi-checkbox-marked-circle-outline' ) || 'mdi-checkbox-marked-circle-outline'
      this.timer = data.timer || 3000
      this.showSnackbar = true
    }
  }
}
</script>

<style scoped>

</style>