import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/styles.scss'
import {LoadingPlugin} from 'vuetify-loading-overlay'
import Icon from '@/components/Icon'
import VueKonva from 'vue-konva';

Vue.config.productionTip = false
Vue.use(LoadingPlugin, { spinnerProps: { color:"primary"},  overlayProps: { color: "white" } })
Vue.use(VueKonva)

Vue.component('icon', Icon)

Vue.prototype.$eventBus = new Vue();

store.commit('initializeStore')

const VueApp = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

export default VueApp
