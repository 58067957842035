import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

var store = new Vuex.Store({
  state: {
    Login: false,
    Token: '_',
    RefreshToken: '',
    UserId:'',
    Username:'',
    IsStaff: false,
    darkMode: null
  },
  mutations: {
    initializeStore (state) { if (localStorage.getItem('store')) { this.replaceState(Object.assign(state, JSON.parse(localStorage.getItem('store')))) } },
    login (state, res) {
      Vue.set(state, 'Login', true)
      Vue.set(state, 'Token', res.access)
      Vue.set(state, 'RefreshToken', res.resfresh)
      Vue.set(state, 'UserId', res.id)
      Vue.set(state, 'IsStaff', res.is_staff)
      Vue.set(state, 'Username', res.user)
    },
    darkModeState(state, darkMode){
      Vue.set(state,'darkMode', darkMode)
    },
    logout (state) {
      Vue.set(state, 'Login', false)
      Vue.set(state, 'Token', '_')
      Vue.set(state, 'RefreshToken', '_')
      Vue.set(state, 'IsStaff', false)
      Vue.set(state, 'UserId', '')
      Vue.set(state, 'Username', '')
    }    
  },
  actions: {
  },
  modules: {
  }
})
store.subscribe((mutation, state) => { localStorage.setItem('store', JSON.stringify(state)) })
export default store
