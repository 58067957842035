<template>
  <svg width="0" height="0" class="icon" :style="styles">
    <use :xlink:href="require('@/assets/img/icons.svg') + '#' + id"></use>
 </svg>
</template>
<script>
export default {
  name: "Icon",
  props:
  {
    id:{
      type: String,
      default: 'menu'
    },
    size:{
      type: String,
      default: null
    },
    color:{
      type:String,
      default: null
    }
  },
  computed:{
    styles(){
      let s = this.size ? 'font-size:'+this.size :''
      s += this.color ? ';color:' + (this.color == 'primary' ? this.$vuetify.theme.themes.light.primary : this.color ) : ''
      return s
    }
  }
  
}
</script>
<style scoped>
svg.icon{
  fill: currentColor;
  height: 1em;
  width: 1.05em;
  vertical-align: middle;
  margin-bottom: 0.125em;
}
</style>