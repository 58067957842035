<template>
<v-app>
    <router-view />
  <notify ref="notify"/>
</v-app>
</template>
<script>
import notify from '@/components/notify'
import store from '@/store'
export default {
  components: {notify},
  mounted() {
    this.$root.notify = this.$refs.notify
    if(this.$vuetify.theme.isDark != store.state.darkMode ){
      this.$vuetify.theme.isDark  = store.state.darkMode
    }
  },
  computed:{
    darkMode(){
      return store.state.darkMode
    }
  },
  watch:{
    darkMode(val){
      this.$vuetify.theme.isDark  = val
    }
  }


}
</script>

<style scoped>
 ::v-deep .v-tooltip__content{
  border: 1px solid rgb(3, 147, 168,0.2) !important;
  /* border-color:rgb(3, 147, 168,0.2); */
  border-radius: 6px;
  backdrop-filter: blur(4px) !important;
  box-shadow: 0px 4px 5px 3px rgba(217, 217, 217, 0.6);
  font-family: 'IBM Plex sans';
  font-size: 12px !important;
  background-color: white;
  color: black;
  opacity: .9;
}

 .theme--dark ::v-deep .v-tooltip__content{
  box-shadow: none;
  background-color: black !important;
  opacity: .8;
  color: white !important;
}



::v-deep .theme--light.v-label {
  color: rgba(0, 0, 0, 0.9) !important;
}

::v-deep .theme--dark.v-label {
  color: rgba(255, 255, 255, 0.8) !important;
}

/* width */
::v-deep ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::v-deep ::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::v-deep ::-webkit-scrollbar-thumb {
  background: #2bacc080; 
}

/* Handle on hover */
::v-deep ::-webkit-scrollbar-thumb:hover {
  background: #0393A890;
}

</style>
