import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'
Vue.use(Vuetify);

export default new Vuetify({
  defaults:{
    VCombobox:{
      height: '100px',
    }
  },
  icons:{
    iconfont:  'mdi' //'fa' ||
  },
  theme: {
    dark: false,
    default: 'light',
    disable: false,
    options: {
      customProperties: true,
      cspNonce: undefined,
      minifyTheme: undefined,
      themeCache: undefined,
    },
    themes: {
      light: {
        primary: '#0393A8',
        secondary: '#038497',
        accent: '#c8d8e4',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
        neutral: '#CBCCD0',
      },
      dark: {
        primary: '#2196F3',
        secondary: '#424242',
        accent: '#FF4081',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
    },
  }
});
